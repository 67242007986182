import React, { useRef } from "react";

import dynamic from 'next/dynamic'
import { useForm } from 'react-final-form'
import { useRouter } from "next/router";

const AirportsField = dynamic(async () => (await import("components/search-results/fields")).AirportsField);
const AirportsPills = dynamic(async () => (await import("components/search-results/fields")).AirportsPills);
const DropOffDateField = dynamic(async () => (await import("components/search-results/fields")).DropOffDateField);
const DropOffTimeField = dynamic(async () => (await import("components/search-results/fields")).DropOffTimeField);
const PickUpDateField = dynamic(async () => (await import("components/search-results/fields")).PickUpDateField);
const PickUpTimeField = dynamic(async () => (await import("components/search-results/fields")).PickUpTimeField);

const DoubleRightIcon = dynamic(async () => (await import("components/icons")).DoubleRightIcon);
const SearchIcon = dynamic(async () => (await import("components/icons")).SearchIcon);

const SearchBox = ({ airportsChoices, currentAirportSlug = null, showAirportsPills = true }) => {
  const airportInputRef = useRef(null);
  const { push } = useRouter();
  const form = useForm();

  // TODO: fix focusing
  // useEffect(() => {
  //   airportInputRef.current?.focus();
  // }, [airportInputRef.current]);

  return (
    <div className="lg:max-w-2xl ml-auto shadow-[0_0_40px_#92387766]">
      <div className="p-6 bg-pink-500 grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-10">
        <div className="md:col-span-2">
          <AirportsField
            airportsChoices={airportsChoices}
            dark
            ref={airportInputRef}
          />
          {showAirportsPills && <AirportsPills airportsChoices={airportsChoices} currentAirportSlug={currentAirportSlug} dark />}
        </div>
        <div className="flex items-start gap-2">
          <DropOffDateField dark />
          <DropOffTimeField dark />
        </div>
        <div className="flex items-start gap-2">
          <PickUpDateField dark />
          <PickUpTimeField dark />
        </div>
      </div>
      <button
        className="w-full p-6 bg-pink-700 text-white font-semibold text-left flex flex-row items-center gap-2 justify-between group"
        onClick={async () => await form.submit()}
        // here if needed:
        // onClick={() => push('/blog/travel-guides/cheapairportparking-launching-soon')}
      >
        <div className="flex flex-row items-center gap-2">
          <SearchIcon className="w-7 h-7" /> Search prices
        </div>
        <div className="transition ease-out duration-500 invisible -translate-x-12 group-hover:translate-x-0 group-hover:visible flex flex-row items-center">
          <DoubleRightIcon className="w-5 h-5" />
        </div>
      </button>
    </div>
  );
};

export default SearchBox;
